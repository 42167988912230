<template>
  <div class="container mescroll-touch">
    <div class="titleBox">
      <div class="title">
        <img class="screenIcon" src="@/assets/images/new_home_icon_alt@2x.png" />
        <span>最新推荐</span>
      </div>
    </div>

    <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
      <div class="list" id="dataList">
        <div class="item" v-for="(item, index) in list" :key="index" @click="toContentPage(item.Id, item.Type)">
          <div class="item_img">
            <img class="item_cover" :src="item.Thumbnail" />
            <img class="playIcon" v-if="item.Type == 1 || item.Type == 3" src="@/assets/images/play_comm_icon@2x.png" />
          </div>
          <div class="item_content">
            <span class="item_title">{{item.Title}}</span>
            <p class="item_time">{{item.yyyymmdd}}</p>
            <div class="item_flexbox">
            <div class="item_nums">
              <div class="nums">
                <img class="watchIcon" src="@/assets/images/watch_yellow.png" />
                <span>{{item.WatchCount}}</span>
              </div>
              <div class="nums">
                <img class="likeIcon" src="@/assets/images/like_yellow.png" />
                <span>{{item.LikeCount}}</span>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </mescroll-vue>
  </div>
</template>

<script>
import MescrollVue from 'mescroll.js/mescroll.vue'
export default {
  data() {
    return {
      list: [],
      PageIndex: 0,
      PageSize: 10,
      mescroll: null, // mescroll实例对象
      mescrollDown:{
        use: false,
        isLock: false
      },
      mescrollUp: { // 上拉加载的配置.
        isBounce: false,
			  callback: this.upCallback, // 上拉回调
				page: {
					num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
					size: 10 //每页数据条数,默认10
				},
        noMoreSize: 0,
				htmlNodata: '<p class="upwarp-nodata"></p>',
        toTop: {
          warpId : 'toTop',
          src: './static/image/top_comm_icon@2x.png' // 回到顶部按钮的图片路径,支持网络图
        },
        empty: {
          // 列表第一页无任何数据时,显示的空提示布局; 需配置warpId才生效;
          warpId: 'dataList', // 父布局的id;
          icon: './static/image/none_comm_png@2x.png', // 图标,支持网络图
          tip: '敬请期待', // 提示
        }
			}
    }
  },
  components: {
    MescrollVue
  },
  computed: {
    tcode() {
      return this.$route.query.tcode;
    },
  },
  methods: {
    getList(PageIndex, PageSize) {
      this.PageIndex = PageIndex;
      this.PageSize = PageSize;
      setTimeout(() => {
        this.$axios.post('/Api/Api/Web/Article/GetArticleList', {
          SubjectTCode: this.tcode,
          ModuleType: 2,
          PageIndex: this.PageIndex,
          PageSize: this.PageSize
        }).then((res) => {
          let arr = [];
          if(res.Data.List) {
            arr = res.Data.List;
          }
          // 如果是第一页需手动置空列表
          if (PageIndex === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr);
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            this.mescroll.endSuccess(arr.length)
          })
        }).catch((e) => {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          this.mescroll.endErr()
        })
      }, 200);
    },
    // mescroll组件初始化的回调,可获取到mescroll对象
		mescrollInit (mescroll) {
			this.mescroll = mescroll  // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
		},
    // 上拉回调
		upCallback (page) {
      this.getList(page.num, page.size);
		},
    toContentPage(Id, Type) {
      this.scrollTop = this.$refs.mescroll.$el.scrollTop;
      this.$router.push({
        path: 'contentPage',
        query: {
          id: Id,
          type: Type
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #F9F9F9;
  .titleBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin: 15px;
    .title {
      width: auto;
      display: flex;
      align-items: center;
      height: 30px;
      border-radius: 30px;
      padding: 0 10px;
      box-sizing: border-box;
      font-size: 14px;
      background-color: $themeColor;
      color: #FFFFFF;
      img {
        display: block;
        width: 16px;
        height: 14px;
        margin-right: 4px;
      }
    }
  }
  .mescroll {
    position: fixed;
    top: 60px;
    bottom: 0;
    height: auto;
    padding: 0 15px;
    box-sizing: border-box;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      width: calc(calc(100% / 2) - 5px);
      border-radius: 6px;
      margin-bottom: 10px;
      background-color: #FFFFFF;
      overflow: hidden;
      .item_img {
        position: relative;
        .item_cover {
          display: block;
          width: 100%;
        }
        .playIcon {
          position: absolute;
          right: 12px;
          bottom: 12px;
          width: 30px;
          height: 30px;
        }
      }
      .item_content {
        padding: 5px 8px 8px;
        .item_title {
          height: 35px;
          font-size: 13px;
          line-height: 17px;
          color: #333333;
          overflow: hidden;
          text-overflow:ellipsis;
          display:-webkit-box;
          -webkit-box-orient:vertical;
          -webkit-line-clamp:2;
          word-wrap: break-word;
        }
        .item_time {
          font-size: 12px;
          color: #999999;
          line-height: 24px;
        }
        .item_label {
          display: flex;
          flex-wrap: wrap;
          span {
            display: inline-block;
            height: 20px;
            line-height: 20px;
            border-radius: 2px;
            padding: 0 6px;
            margin-right: 6px;
            margin-bottom: 5px;
            background-color: rgba(255, 73, 98, 0.15);
            font-size: 11px;
            color: #CA001B;
          }
        }
        .item_nums {
          display: flex;
          justify-content: flex-end;
          .nums {
            display: flex;
            align-items: center;
            .watchIcon {
              width: 14px;
              margin: 0 2px 0 6px;
            }
            .likeIcon {
              width: 11px;
              margin: 0 2px 0 6px;
            }
            span {
              font-size: 11px;
              color: #666666;
            }
          }
        }
      }
    }    
    .item:nth-child(even) {
      margin-left: 10px;
    }
  }
}
</style>